<template>
    <section>
        <section class="d-flex flex-row mb-5">
            <v-text-field
                solo
                dense
                flat
                class="col-xl-3 col-lg-4 custom-border mr-1 general-custom-field f14 poppins fw500"
                hide-details
                prepend-inner-icon="mdi-magnify"
                placeholder="Search"
                v-model="search"
            />
            <v-btn color="primary" @click="getManualEnrollments()">
                Search
            </v-btn>
        </section>
        <section class="d-flex flex-row mb-5">
            <v-btn icon
                v-bind="attrs"
                v-on="on"
                small
                color="primary"
                @click="$router.push({ name: 'Instructor Enrollment'})">
                <v-icon>
                    mdi-chevron-left
                </v-icon>
            </v-btn>
            <span class="fw500 secondary-1--text ml-1 poppins">
                {{ name }}
            </span>
        </section>
        <v-data-table
            :loading="loading"
            :headers="enrollmentStudent"
            :items="courses"
            :search="search"
            class="text--center custom-border poppins">
            <template v-slot:item.created_at="{ item }">
                <span>
                    {{ $dateFormat.dayDateTime(item.created_at) }}  {{ item.status }}
                </span>
            </template>
            <template v-slot:item.status="{ item }">
                <v-chip
                    v-if="item.status === 1"
                    class="ma-1 fw500 f12"
                    color="green lighten-5"
                    text-color="green"
                    label
                >
                    APPROVED
                </v-chip>
                <v-chip
                    v-if="item.status === 2"
                    class="ma-1 fw500 f12"
                    color="grey lighten-4"
                    text-color="grey"
                    label
                >
                    PENDING
                </v-chip>
                <v-chip
                    v-if="item.status === 3"
                    class="ma-1 fw500 f12"
                    color="red lighten-4"
                    text-color="red"
                    label
                >
                    DISQUALIFIED
                </v-chip>
            </template>
            <template v-slot:item.course="{ item }">
                <span>
                    {{ item?.course?.title }}
                </span>
            </template>
            <template v-slot:item.updated_at="{ item }">
                <span>
                    {{ $dateFormat.dayDateTime(item.updated_at) }}
                </span>
            </template>
            <template v-slot:item.image="{ item }">
                <a
                    v-if="item.image"
                    :href="item?.image?.url"
                    class="primary--text text-decoration-none f12"
                    target="_blank"
                    >
                    View Receipt
                </a>
                <span v-else >-</span>
            </template>
        </v-data-table>
        <v-dialog
            v-model="fullDialog"
            fullscreen
            overlay
            persistent
            >
            <v-card class="fill-height" @click="closeViewFull" max-width="500">
                <section>
                    <v-btn color="secondary" icon top class="mt-2 ml-auto" @click="closeViewFull" dense>
                        <v-icon large>mdi-close-thick</v-icon>
                    </v-btn>
                </section>
                
                <div class="container-frame">
                    <iframe 
                        class="responsive-iframe"
                        :src="selectedImage">
                    </iframe>
                </div>
            </v-card>
        </v-dialog>
    </section>
</template>

<script>
import {mapState, mapActions} from 'vuex'
import {enrollmentStudent} from '../../constants/tblheaders/enrollment/enrollment'

export default {
    data: () => ({
        enrollmentStudent,
        loading: false,
        dialog: false,
        user: null,
        search: '',
        paginate: '10',
        page: 1,
        pageCount: 1,
        courses: [],
        selectedImage: null,
        fullDialog: false,
        name: ''
    }),
    computed: {
        ...mapState('instructor', {
            enroll_students: (state) => state.enroll_students
        })
    },
    methods: {
        ...mapActions('instructor', ['getEnrolledStudentAction']),
        getUserEnrollee(){
            this.loading = true
            this.getEnrolledStudentAction(this.$route.params.id).then((res) => {
                this.courses = res?.enrollments
                this.name = `${res.first_name ? res.first_name : 'Student'} ${res.last_name ? res.last_name : 'Account'} ${ res.suffix ? res.suffix : '' }`
            }).finally(() => {
                this.loading = false
            })
        },
        showImage(image) {
            this.selectedImage = image
            this.fullDialog = true
        },
        closeViewFull () {
            this.fullDialog = false
        },
    },
    created() {
        this.getUserEnrollee()
    }
}
</script>